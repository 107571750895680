import React from 'react'

import Gallery from '../Gallery'
import * as El from '../Grid/Container'

const Event = ({ data }) => (
  <El.Container>
    <div
      dangerouslySetInnerHTML={{
        __html: data.section1.childMarkdownRemark.html
      }}
    />
    {data.isGalleryVisible && <Gallery images={data.images} />}
    <div
      dangerouslySetInnerHTML={{
        __html: data.section2.childMarkdownRemark.html
      }}
    />
  </El.Container>
)

export default Event
